import styled from "styled-components"
import { StandardBtn } from "../../../styled"
import Img from "gatsby-image"

const currentColor = props =>
  props.categories === "Immobilier"
    ? props.theme.colors.green
    : props.theme.colors.yellow

export const Card = styled.div`
  border-radius: 7px;
  text-align: center;
  position: relative;
  box-shadow: -1px 17px 28px 1px rgba(0, 0, 0, 0.08);
  background-color: ${props => props.theme.colors.white};
  max-width: 260px;
  flex: 1 0 21%;
  bottom: 0px;
  transition: all 400ms;
  z-index: 2;
  min-height: 360px;

  margin: ${props => (props.isHomePage ? "0 auto !important" : "20px")};

  ${props => props.theme.media.mediumPlus`
    max-width:unset;
    width: 260px;
  `}

  &:hover {
    transform: scale(0.95);
    cursor: pointer;
    bottom: -15px;
    transition: all 400ms;
    box-shadow: -1px 17px 68px 1px rgba(0, 0, 0, 0);

    button {
      background-color: ${props => props.theme.colors.white};
      color: ${props => props.theme.colors.lightBlue};
      border: 1px solid ${props => props.theme.colors.lightBlue};
    }
  }
`

export const SecondCard = styled(Card)`
  min-height: unset;
  transform: scale(0.95);
  bottom: 105px;
  height: 100px;
  z-index: 1;
  margin-bottom: -70px;

  &:hover {
    bottom: 30px;
    height: 30px;
  }
`

export const Header = styled.div`
  background-color: ${currentColor};
  height: 105px;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
`

export const Body = styled.div`
  min-height: 140px;
  overflow: hidden;
`

export const Footer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
`

export const Picture = styled(Img)`
  width: 50%;
  bottom: 65px;
  margin-bottom: -65px;
  position: relative;
  border: 5px solid ${props => props.theme.colors.white};
  border-radius: 50%;
  margin: 0 auto -65px;
`
export const Title = styled.h2`
  font-size: 15px;
  line-height: 24px;
  text-align: left;
  padding: 10px 30px;
`
export const Cta = styled(StandardBtn)`
  background-color: ${props => props.theme.colors.lightBlue};
  margin-bottom: 20px;
  text-transform: uppercase;
  font-size: 11px;
  padding: 5px 25px;

  &:hover {
    color: ${props => props.theme.colors.lightBlue};
    border: 1px solid ${props => props.theme.colors.lightBlue};
  }
`
